import { faker } from '@faker-js/faker';
import { createGetHandler } from '../testing/msw/handlerUtils';
import { AssetDetailResponseBuilder } from '../testing/platformAssetMocks';
const assetEndpoints = {
    load: '/api/v1/Asset/load',
};
const assetLoadHandler = createGetHandler(assetEndpoints.load, [...new Array(faker.number.int({ min: 1, max: 1 })).keys()].map((_) => new AssetDetailResponseBuilder({}).build()));
const assetHandlers = [assetLoadHandler];
export const assetHandler = {
    endpoints: assetEndpoints,
    handlers: assetHandlers,
};
